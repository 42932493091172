import React from 'react'
import { baseurl } from '../../metadata'
import { Container } from './Endpoint.styles'
import { CurlGenerator } from 'curl-generator'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import IResponse from '../../interfaces/IResponse'
import Request from './Request/Request'
import { getQueryParams } from '../../helpers/Utils'

const Endpoint: React.FC<any> = ({ request, ...endpoint }) => {
  const curl = CurlGenerator(request)

  const queryParams = getQueryParams(request.url)

  return (
    <Container className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-7 context p-4 pt-5 pb-5">
          <h1>{endpoint.name}</h1>

          {endpoint.usage.length > 0 && (
            <p>{endpoint.usage}</p>
          )}

          <table className="table table-hover">
            <tbody>
              <tr>
                <td style={{ width: 130 }}>API Reference</td>
                <td>
                  <small>{endpoint.name}</small>
                </td>
              </tr>

              <tr>
                <td style={{ width: 130 }}>Endpoint</td>
                <td>
                  <small>{baseurl}{request.url.split('?')[0]}</small>
                </td>
              </tr>

              <tr>
                <td style={{ width: 130 }}>HTTP Method</td>
                <td>
                  <small>{request.method}</small>
                </td>
              </tr>

              {request.headers?.Authorization != null && (
                <tr>
                  <td style={{ width: 130 }}>Authorization</td>
                  <td>
                    <small>Bearer &lt;token&gt;</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {request.body != null && (
            <div className="card mb-3">
              <div className="card-header">
                <div className="card-title mb-0">Request Body</div>
              </div>

              <div className="card-body">
                <pre className="mb-0">
                  <code>
                    {'{'}
                    {Object.entries(request.body as Record<string, string>).map(([key, value], index) => (
                      <div key={index}>
                        <span>&nbsp;&nbsp;&quot;{key}&quot;:</span>
                        <span>
                          &nbsp;{value}{index < Object.entries(request.body).length - 1 && ','}
                        </span>
                      </div>
                    ))}
                    {'}'}
                  </code>
                </pre>
              </div>
            </div>
          )}

          {Object.values(queryParams).length > 0 && (
            <div className="card mb-3">
              <div className="card-header">
                <div className="card-title mb-0">Query Params</div>
              </div>

              <div className="card-body">
                <pre className="mb-0">
                  <code>
                    {JSON.stringify(queryParams, null, 2)}
                  </code>
                </pre>
              </div>
            </div>
          )}

          <Request request={request} />
        </div>

        <div className="col-12 col-md-5 examples pt-3">
          <div className="position-sticky">
            <pre className="p-3 mb-3">
              {request.method} {baseurl}{request.url}
            </pre>

            <SyntaxHighlighter language="curl" style={anOldHope} className="p-3 mb-3">
              {curl}
            </SyntaxHighlighter>

            {endpoint.responses.map((response: IResponse, index: number) => (
              <SyntaxHighlighter language="json" style={anOldHope} showLineNumbers className="p-3 mb-3" key={index}>
                {JSON.stringify(response.body, null, 2)}
              </SyntaxHighlighter>
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Endpoint
